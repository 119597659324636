<mat-card appearance="raised" style="border: 1px solid grey !important;" class="fx-card shadow-2xl border-l">
    <mat-toolbar
            color="accent"
            class="fx-card-toolbar fx-height-46 fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4"
    >
        <mat-icon fontSet="mdi" fontIcon="mdi-account"></mat-icon>
        <span
        >{{ physician.fullName }} &nbsp;({{ "LIST_OF_EXAMS" | translate }})</span
        >
        <span class="fx-grow-1"></span>
        <button (click)="exportExcel()" mat-raised-button color="primary">
            <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-file-excel"
            ></mat-icon>
            {{ "EXPORT_EXCEL" | translate }}
        </button>
        <button mat-raised-button color="accent" (click)="closeEvent.emit(true)">
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
            {{ "CLOSE" | translate }}
        </button>
    </mat-toolbar>
    <div
            class="fx-search-area fx-layout-row fx-content-end fx-items-end fx-gap-4 fx-padding-8"
            [formGroup]="filterForm"
    >
        <mat-form-field class="fx-grow-1" subscriptSizing="dynamic">
            <mat-icon
                    matIconPrefix
                    fontIcon="mdi-magnify"
                    fontSet="mdi"
                    tabindex="-1"
            ></mat-icon>
            <input
                    matInput
                    #filter
                    [placeholder]="'SEARCH' | translate"
                    formControlName="key"
            />
        </mat-form-field>
        <span class="fx-grow-1"></span>
        <div class="fx-layout-column-nowrap">
            <h5 style="margin: 6px 0">{{ "REPORT_STATUS" | translate }}</h5>
            <mat-button-toggle-group
                    aria-label="Report status"
                    formControlName="reportStatus"
                    multiple
                    name="reportStatus"
            >
                @for (rule of columnsFormattingRules | filterBy: 'REPORTING_STATUS';
                    track $index) {
                    <mat-button-toggle
                            [matTooltip]="rule.primaryFormatValue | translate"
                            [value]="rule.primaryFormatValue"
                    >
                        @switch (rule.formattingStyle?.displayMode) {
                            @case ('ICON') {
                                <mat-icon
                                        [fontIcon]="rule.formattingStyle?.icon"
                                        [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                        fontSet="mdi"
                                ></mat-icon>
                            }
                            @default {
                                <span [style.color]="rule.formattingStyle?.color">{{
                                        rule.primaryFormatValue | translate
                                    }}</span>
                            }
                        }
                    </mat-button-toggle>
                }
            </mat-button-toggle-group>
        </div>
        <div class="fx-layout-column-nowrap">
            <h5 style="margin: 6px 0">{{ "PATIENT_STATUS" | translate }}</h5>
            <mat-button-toggle-group
                    aria-label="patient status"
                    formControlName="patientStatus"
                    multiple
                    name="patientStatus"
            >
                @for (rule of columnsFormattingRules | filterBy: 'PATIENT_STATUS';
                    track $index) {
                    <mat-button-toggle
                            [matTooltip]="rule.primaryFormatValue | translate"
                            [value]="rule.primaryFormatValue"
                    >
                        @switch (rule.formattingStyle?.displayMode) {
                            @case ('ICON') {
                                <mat-icon
                                        [fontIcon]="rule.formattingStyle?.icon"
                                        [ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
                                        fontSet="mdi"
                                ></mat-icon>
                            }
                            @default {
                                <span [style.color]="rule.formattingStyle?.color">{{
                                        rule.primaryFormatValue | translate
                                    }}</span>
                            }
                        }
                    </mat-button-toggle>
                }
            </mat-button-toggle-group>
        </div>
        @if (group.value === 'OT') {
            <div class="fx-layout-row-nowrap fx-content-start fx-items-end fx-gap-4">
                <mat-form-field style="width: 200px" subscriptSizing="dynamic">
                    <mat-date-range-input [rangePicker]="picker">
                        <input
                                [placeholder]="'FROM' | translate"
                                formControlName="startDate"
                                matStartDate
                                (dateInput)="changePeriod()"
                        />
                        <input
                                [placeholder]="'TO' | translate"
                                formControlName="endDate"
                                matEndDate
                                (dateInput)="changePeriod()"
                        />
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker
                        #picker
                        (closed)="changePeriod()"
                    ></mat-date-range-picker>
                </mat-form-field>
            </div>
        }
        <div class="fx-layout-column-nowrap">
            <h5 style="margin: 6px 0">{{ "PERIOD" | translate }}</h5>
            <mat-button-toggle-group
                #group="matButtonToggleGroup"
                (change)="changeRange($event)"
                aria-label="Period"
                formControlName="period"
                name="period"
            >
                <mat-button-toggle value="TODAY">
                    {{ "TODAY" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="YESTERDAY">
                    {{ "YESTERDAY" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="3D">
                    {{ "THREE_DAYS" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="1W">
                    {{ "ONE_WEEK" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="1M">
                    {{ "ONE_MONTH" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="3M">
                    {{ "THREE_MONTHS" | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="OT">
                    {{ "ALL" | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="fx-layout-column fx-fill">
        <div style="overflow: auto; height: calc(100vh - 290px)">
            <mat-table
                    [dataSource]="dataSource"
                    [matSortActive]="sorting.active"
                    [matSortDirection]="sorting.direction"
                    [trackBy]="trackById"
                    class="fx-fill-height"
                    matSort
                    [matSortDisableClear]="true"
            >
                <!--Table-->
                @for (col of displayedColumns; track $index) {
                    <ng-container
                            [class.hidden]="col.hidden"
                            matColumnDef="{{ col.label }}"
                    >
                        <mat-header-cell
                                *matHeaderCellDef
                                [disabled]="!col.sortable"
                                mat-sort-header="{{ col.value }}"
                        >
                            {{ col.header | translate }}
                        </mat-header-cell>

                        @switch (col.type) {
                            @case ('DATE') {
                                <mat-cell
                                        *matCellDef="let row"
                                        [ngStyle]="getColumnStyle(col.type, col, row)"
                                >
                                    <span>{{ row[col.label] | date : todayFormat }}</span>
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            }
                            @case ('BOOLEAN') {
                                <mat-cell
                                        *matCellDef="let row"
                                        [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                >
                                    @switch (getColumnDisplayMode(col.header)) {
                                        @case ('ICON') {
                                            <mat-icon
                                                    [fontIcon]="getColumnFormattingIcon(col.header, row[col.label])"
                                                    [matTooltip]="
                (col.label === 'billed' && row[col.label]
                  ? 'N° ' + row['billNumber']
                  : row[col.label]
                  ? 'YES'
                  : ''
                ) | translate
              "
                                                    matTooltipPosition="left"
                                                    [ngStyle]="
                getColumnFormattingIconStyle(col.header, row[col.label])
              "
                                                    fontSet="mdi"
                                            ></mat-icon>
                                        }
                                        @case ('BADGE') {
                                            <span
                                                    [ngStyle]="getColumnStyleDisplayMode(col.type, col, row, 'BADGE')"
                                                    class="ft-badge"
                                            >{{ (row[col.label] ? "YES" : "NO") | translate }}</span
                                            >
                                        }
                                        @default {
                                            {{ (row[col.label] ? "YES" : "NO") | translate }}
                                        }
                                    }
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            }
                            @case ('STATUS') {
                                <mat-cell
                                        *matCellDef="let row"
                                        [ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
                                >
                                    @switch (getColumnDisplayMode(col.header)) {
                                        @case ('ICON') {
                                            <mat-icon
                                                    (click)="
                $event.stopPropagation();
                col.header === 'REPORTING_STATUS' ? openReport(row) : null
              "
                                                    [fontIcon]="
                col.header === 'CONNECTED'
                  ? getConnectedIcon(col.header, row[col.label])
                  : getColumnFormattingIcon(col.header, row[col.label])
              "
                                                    [matTooltip]="row[col.label] | translate"
                                                    matTooltipPosition="left"
                                                    [ngStyle]="
                getColumnFormattingIconStyle(col.header, row[col.label])
              "
                                                    [style.cursor]="
                col.header === 'REPORTING_STATUS' ? 'pointer' : ''
              "
                                                    fontSet="mdi"
                                            ></mat-icon>
                                        }
                                        @case ('BADGE') {
                                            <span
                                                    [ngStyle]="getColumnBooleanBadgeStyle(col.header, row[col.label])"
                                                    class="ft-badge"
                                            >{{ row[col.label] | translate }}</span
                                            >
                                        }
                                        @default {
                                            {{ row[col.label] | translate }}
                                        }
                                    }
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            }
                            @case ('NUMERIC') {
                                <mat-cell
                                        *matCellDef="let row"
                                        [ngStyle]="getColumnStyle(col.type, col, row)"
                                >
                                    {{
                                        row
                                            | sumWorkItemBy : col.label
                                            | numeric
                                            : {
                                                numberFormat,
                                                comma: col.label != "count"
                                            }
                                    }}
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            }
                            @default {
                                @if (col.unit === 'ICON') {
                                    <mat-cell *matCellDef="let row">
                                        @if (row[col.label]) {
                                            <mat-icon
                                                    [fontIcon]="'mdi-message'"
                                                    [matTooltipClass]="'alert-tooltip'"
                                                    [matTooltip]="getNoteAlert(row[col.label])"
                                                    [ngStyle]="{ color: '#00BCD4' }"
                                                    fontSet="mdi"
                                            ></mat-icon>
                                        }
                                    </mat-cell>
                                }
                                @if (col.unit !== 'ICON') {
                                    <mat-cell
                                            *matCellDef="let row"
                                            [ngStyle]="getColumnStyle(col.type, col, row)"
                                    >
                                        @if (col.label === 'patientName') {
                                            <div
                                                    class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
                                            >
                                                <a
                                                        [routerLink]="'/patients/folder/' + row.patientId"
                                                        class="ft-patient-link"
                                                >{{ row[col.label] }}</a
                                                >
                                                @if (row.smsSent) {
                                                    <mat-icon
                                                            [matTooltip]="row.smsContent"
                                                            [style.color]="'#607D8B'"
                                                            fontIcon="mdi-email-send"
                                                            fontSet="mdi"
                                                    ></mat-icon>
                                                }
                                            </div>
                                        }
                                        @if (col.label !== 'patientName') {
                                            <span
                                                    [matTooltip]="
                ['insuranceName', 'procedureCode', 'sideEffects'].includes(
                  col.label
                )
                  ? (row[col.label] | translate)
                  : null
              "
                                            >{{ row[col.label] | translate }}</span
                                            >
                                        }
                                    </mat-cell>
                                }
                                <mat-footer-cell *matFooterCellDef
                                ><span style="width: 100%">{{
                                        calculateTotal(col.label)
                                    }}</span></mat-footer-cell
                                >
                            }
                        }
                    </ng-container>
                }
                <!--actions-->
                <ng-container matColumnDef="action" stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell (click)="$event.stopPropagation()" *matCellDef="let row">
                        <div
                                class="fx-layout-row-nowrap fx-content-end fx-items-center"
                                style="width: 100%"
                        >
                            <mat-menu #viewerMenu="matMenu" [overlapTrigger]="true">
                                @for (viewer of viewers; track $index) {
                                    <button (click)="openStudy(row, viewer, true)" mat-menu-item>
                                        {{ viewer.name }}
                                    </button>
                                }
                            </mat-menu>
                            <mat-menu #wfMenu="matMenu" [overlapTrigger]="true">
                                <button
                                        (click)="openReport(row, true)"
                                        [disabled]="
                    cannot('editReport') || !isGranted(row, 'editReport')
                  "
                                        mat-menu-item
                                >
                                    <mat-icon fontIcon="mdi-open-in-new" fontSet="mdi"></mat-icon>
                                    {{ "REPORT_NEW_TAB" | translate }}
                                </button>

                                <button (click)="addComment(row)" mat-menu-item>
                                    <mat-icon
                                            fontIcon="mdi-comment-alert"
                                            fontSet="mdi"
                                    ></mat-icon>
                                    {{ "ADD_COMMENT" | translate }}
                                </button>
                                <mat-divider></mat-divider>
                                <button (click)="printAttestation(row)" mat-menu-item>
                                    <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                    {{ "PRINT_ATTESTATION" | translate }}
                                </button>
                                @if (generalSetting?.reportPrintMode === 'CHROME') {
                                    <button
                                            (click)="printReportingTask(row)"
                                            [disabled]="
                    cannot('printReport') || !isGranted(row, 'printReport')
                  "
                                            mat-menu-item
                                    >
                                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                        {{ "PRINT_REPORT" | translate }}
                                    </button>
                                    <button
                                            (click)="printBooklet(row)"
                                            [disabled]="
                    cannot('printReport') || !isGranted(row, 'printBooklet')
                  "
                                            mat-menu-item
                                    >
                                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                        {{ "PRINT_BOOKLET" | translate }}
                                    </button>
                                }
                                <ng-template>
                                    <button
                                            [disabled]="
                      cannot('printReport') || !isGranted(row, 'printReport')
                    "
                                            [matMenuTriggerFor]="printersMenu"
                                            mat-menu-item
                                    >
                                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                        {{ "PRINT_REPORT" | translate }}
                                    </button>
                                    <mat-menu #printersMenu="matMenu">
                                        @for (printer of printers; track $index) {
                                            <button
                                                    (click)="printReportingTask(row, printer['label'])"
                                                    mat-menu-item
                                            >
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{ printer["label"] }}
                                            </button>
                                        }
                                    </mat-menu>
                                    <button
                                            [disabled]="
                      cannot('printReport') || !isGranted(row, 'printBooklet')
                    "
                                            [matMenuTriggerFor]="printersMenu2"
                                            mat-menu-item
                                    >
                                        <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                        {{ "PRINT_BOOKLET" | translate }}
                                    </button>

                                    <mat-menu #printersMenu2="matMenu">
                                        @for (printer of printers; track $index) {
                                            <button
                                                    (click)="printBooklet(row, printer['label'])"
                                                    mat-menu-item
                                            >
                                                <mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
                                                {{ printer["label"] }}
                                            </button>
                                        }
                                    </mat-menu>
                                </ng-template>

                                <button
                                        (click)="showPatientFolder(row)"
                                        [disabled]="!can(row, 'viewPatientFolder')"
                                        [matTooltip]="'PATIENT_FOLDER' | translate"
                                        mat-menu-item
                                >
                                    <mat-icon
                                            fontIcon="mdi-folder-account"
                                            fontSet="mdi"
                                    ></mat-icon>
                                    {{ "PATIENT_FOLDER" | translate }}
                                </button>

                                @if (profile && profile.manageExam !== 'NONE' && row.count ===
                                1 && notPaid(row)) {
                                    <mat-divider></mat-divider>
                                    <button (click)="deleteExam(row)" mat-menu-item>
                                        <mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
                                        {{ "DELETE" | translate }}
                                    </button>
                                }
                            </mat-menu>

                            @if (profile.viewPatientStudies !== 'NONE' && defaultViewer) {
                                <button
                                        (click)="openStudy(row, defaultViewer, true)"
                                        [disabled]="
                  !(row['imagesAvailables'] && row['studyInstanceUID'] !== '')
                "
                                        [matTooltip]="
                  'OPEN_IN' | translate : { viewer: defaultViewer.name }
                "
                                        mat-icon-button
                                >
                                    <mat-icon
                                            [class.btn-action-view]="
                    row['imagesAvailables'] && row['studyInstanceUID'] !== ''
                  "
                                            [fontIcon]="'mdi-monitor-dashboard'"
                                            [fontSet]="'mdi'"
                                            [style.color]="
                    row['imagesAvailables'] && row['studyInstanceUID'] !== ''
                      ? '#00bcd4'
                      : ''
                  "
                                    ></mat-icon>
                                </button>
                            }
                            @if (profile.viewPatientStudies !== 'NONE' &&
                            isRemoteEyeViewer()) {
                                <button
                                        (click)="openStudy(row, 'RELite', true)"
                                        [disabled]="!(row['studyInstanceUID'] !== '')"
                                        [matTooltip]="'OPEN_IN' | translate : { viewer: 'RELite' }"
                                        mat-icon-button
                                >
                                    <mat-icon
                                            [class.btn-action-view]="row['studyInstanceUID'] !== ''"
                                            [fontIcon]="'mdi-monitor-dashboard'"
                                            [fontSet]="'mdi'"
                                            [style.color]="
                    row['studyInstanceUID'] !== '' ? '#096aa0' : ''
                  "
                                    ></mat-icon>
                                </button>
                            }
                            @if (profile.viewPatientStudies !== 'NONE' && viewerOpen) {
                                <button
                                        (click)="openStudy(row, 'RELite', true, false)"
                                        [disabled]="row['studyInstanceUID'] === ''"
                                        [matTooltip]="('ADD_TO' | translate) + defaultViewer.name"
                                        mat-icon-button
                                >
                                    <mat-icon
                                            [class.btn-action-view]="row['studyInstanceUID'] !== ''"
                                            [fontIcon]="'mdi-layers-plus'"
                                            [fontSet]="'mdi'"
                                            [style.color]="
                    row['studyInstanceUID'] !== '' ? '#E91E63' : ''
                  "
                                    ></mat-icon>
                                </button>
                            }
                            @if (profile.viewPatientStudies !== 'NONE' && viewers.length) {
                                <button
                                        [disabled]="
                  !(row['imagesAvailables'] && row['studyInstanceUID'] !== '')
                "
                                        [matMenuTriggerFor]="viewerMenu"
                                        mat-icon-button
                                >
                                    <mat-icon
                                            [class.btn-action-view-other]="
                    row['imagesAvailables'] && row['studyInstanceUID'] !== ''
                  "
                                            fontIcon="mdi-image-multiple"
                                            fontSet="mdi"
                                    ></mat-icon>
                                </button>
                            }

                            <button
                                    [disabled]="row['old']"
                                    [matMenuTriggerFor]="wfMenu"
                                    mat-icon-button
                            >
                                <mat-icon
                                        fontIcon="mdi-dots-horizontal"
                                        fontSet="mdi"
                                ></mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <!--header-->
                <mat-header-row
                        *matHeaderRowDef="columnsToDisplay; sticky: true"
                ></mat-header-row>

                <mat-row
                        [@rowsAnimation]=""
                        *matRowDef="let row; columns: columnsToDisplay"
                        [ngClass]="{ 'fire-row': true, hide: row.hidden }"
                        [ngStyle]="getRowFormattingStyle(row)"
                ></mat-row>

                <mat-footer-row
                        *matFooterRowDef="columnsToDisplay; sticky: true"
                        [class.hidden-footer]="resultsLength === 0"
                        [style.minHeight.px]="32"
                ></mat-footer-row>
            </mat-table>
            <div
                    [style.display]="resultsLength === 0 ? '' : 'none'"
                    class="fx-table-empty"
            >
                @if (isLoadingResults) {
                    <div>
                        <mat-spinner
                                [diameter]="50"
                                [strokeWidth]="3"
                                color="warn"
                        ></mat-spinner>
                    </div>
                } @else {
                    {{ "NO_ITEM" | translate }}
                }
            </div>
        </div>
        <mat-paginator
                [length]="resultsLength"
                [pageIndex]="0"
                [pageSizeOptions]="[20, 50, 100, 150, 200]"
                [pageSize]="defaultPageSize"
                [showFirstLastButtons]="true"
        >
        </mat-paginator>
    </div>
</mat-card>
